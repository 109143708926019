body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.k-form-field input:disabled {
  background-color: rgba(0, 0, 0, 0.05);
}

.login-form {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.ratemodel .k-grid .k-grid-header th .k-cell-inner,
.ratemodel .k-grid .k-grid-header th .k-cell-inner > .k-link {
  display: block;
  text-align: center;
  white-space: normal;
}

.ratemodel .k-grid .k-column-title {
  white-space: normal;
}

.ratemodel .k-grouping-header {
  display: none;
}

.ratemodel .k-grid tr.k-alt {
  background-color: transparent;
}

.ratemodel .k-grid .k-grouping-row td {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.ratesummary .k-grid .k-grid-header th .k-cell-inner,
.ratesummary .k-grid .k-grid-header th .k-cell-inner > .k-link {
  display: block;
  text-align: center;
  white-space: normal;
}

.ratesummary .k-grid .k-column-title {
  white-space: normal;
}

.k-grid .grid-bold-header {
  font-weight: 600;
  color: #192853;
  background-color: #ebe8e8;
}

.k-grid .dark-bg-column {
  background-color: #fafafa;
}

.k-grid .bold-font-coulmn {
  font-weight: 600;
}

.ratemodel .k-grid .k-grid-header .k-header:first-child {
  font-weight: 600;
  color: #192853;
  background-color: #ebe8e8;
}

.ratemodel .k-grid-header {
  padding: 0 !important;
}

.ratemodel .k-grid-content {
  overflow-y: visible;
}

.ratemodel .k-grid-footer {
  padding: 0 !important;
}

.k-grid .highlight-coulmn {
  font-weight: 600;
  color: #1e6cd9;
}

.oe-breadcrumb {
  font-size: 22px !important;
}

.help-links .link.js-help-link {
  display: none;
}

.link.js-cancel {
  display: none;
}

.okta-sign-in-header.auth-header {
  background-color: #192853 !important;
}

.okta-sign-in-header.auth-header img {
  max-width: 300px !important;
}
