.k-drawer-content {
  padding: 0 20px;
}

.k-drawer-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.k-drawer-item {
  user-select: none;
}

.k-dialog-buttongroup {
  border-color: none;
  border-width: 0px;
}

th.k-header.active .k-grid-filter {
  color: #fff;
  background-color: darkgray;
}

.k-upload .k-dropzone .k-dropzone-hint {
  display: none;
}

.k-external-dropzone {
  height: 150px;
}

.k-drawer-container {
  position: relative;
  min-height: calc(100vh - 91px);
}

.stepper-view-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.stepper-view-text {
  flex-basis: 100%;
}

.stepper-view-control {
  flex-basis: 100%;
}

.stepper-view-action {
  flex-basis: 100%;
}

@media screen and (min-width: 600px) {
  .stepper-view-container {
    flex-wrap: nowrap;
  }

  .stepper-view-text {
    order: 1;
    flex-basis: 200px;
  }

  .stepper-view-control {
    order: 2;
    flex-basis: 1;
  }

  .stepper-view-action {
    order: 3;
    flex-basis: 150px;
  }
}

.profile-avatar {
  background-color: rgb(25, 40, 53, 0.9);
  padding: 8px 0px;
  cursor: pointer;
}

.profile-avatar-default {
  padding: 8px 0px;
  cursor: pointer;
}

.profile-avatar-default:hover {
  padding: 8px 0px;
  cursor: pointer;
  background-color: rgb(25, 40, 53, 0.9);
}

.k-stepper .k-step-indicator::after {
  content: none;
}

.green-icon {
  color: green;
}

.red-icon {
  color: red;
}

.oe-multiline-button {
  white-space: normal;
  text-align: start;
}
